import React, { useState, useEffect } from 'react';
import API from '../../../../../libs/api-lib';
import { Button, Form } from 'semantic-ui-react';
import { CHECKBOX } from '../../../../../components/cmp_form/cmp_form';



export default function CRD_FEATURES({ organization_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_mode, set_mode ] = useState('LOADING');
    const [ var_features, set_features ] = useState({});
    const [ var_working_features, set_working_features ] = useState({});
    const [ var_disabled_features, set_disabled_features ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const feature_list = [
        { feature: 'assignments', name: 'Assignments', info: 'Enable the organization to create assignments.' },
        { feature: 'networks', name: 'Affiliate network', sub_category: 'assignments', info: 'Enable the organization to create an affiliate network.' },
        { feature: 'network_invite', name: 'Externally visible', sub_category: 'networks', info: 'Make this organization visible to other entities, so they can invite it to join their affiliate network.' },
        { feature: 'dailycheck', name: 'Daily Check', sub_category: 'assignments', info: 'Create and present a daily questionnaire.' },
        { feature: 'r2w', name: 'Onsite', sub_category: 'assignments', info: 'Allow individuals to declare they are physically on a work site.' },
        { feature: 'qraccess', name: 'QR Access', sub_category: 'assignments', info: 'Enable QR code access for assignments.' },
        { feature: 'requirements', name: 'Requirements', sub_category: 'assignments', info: 'Manage credential compliance with requirements and packages.' },
        { feature: 'credentials', name: 'Credentials', info: 'Allow an Admin to view an individual\'s credentials from an assignment and the worker\'s profile.' },
        { feature: 'manage_credentials', name: 'Manage', sub_category: 'credentials', info: 'Allow an Admin to activate/deactivate an individual\'s credentials.' },
        { feature: 'demoaccount', name: 'Demo ORG', info: 'Create as a demo organization.' },
        { feature: 'documents', name: 'Documents', info: 'Enable the uploading of documents to be acknowledged by individuals.' },
        { feature: 'guest', name: 'Guest', info: 'Allow guests to join the organization.' },
        { feature: 'issuer', name: 'Issuer', info: 'Enable the organization to issue credentials.' },
        { feature: 'credentials_ext', name: 'External Credentials', sub_category: 'issuer', info: 'Make the issuer and its credentials visible to individuals outside of this organization.' },
        { feature: 'reports', name: 'Reports', info: 'Enable reports.' },
        { feature: 'verifiable_credentials', name: 'Verifiable Credentials', info: 'Allow the organization to issue verifiable credentials.' },
        { feature: 'proof_type', name: 'Proof Type Selection', sub_category: 'verifiable_credentials', info: 'Allow the issuer to select the data proof type for the VC.' },
        { feature: 'mfa', name: 'MFA', info: 'Allows users of the organization to opt into MFA.' }
    ];



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_record();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_record() {
        let record = await API_get_organization_features();
        set_features({id: organization_id, ...record});
        set_mode('VIEW');
    }

    async function save_record() {
        set_processing(true);
        try {
            await API_put_organization_features();
            set_features({...var_features, ...var_working_features});
        } finally {
            set_mode('VIEW');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization_features() {
        return API.get('alpha-org', '/get-organization-features/' + organization_id);
    }

    function API_put_organization_features() {
        return API.put('alpha-org', '/put-organization-features', {
            body: var_working_features
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_working_features({...var_features});
        let disabled_features = [];
        for (let property in var_features) {
            if (var_features[property] === 'YES' && property !== 'wallet' && property !== 'reports' && property !== 'manage_credentials') {
                disabled_features.push(property);
            }
        }
        set_disabled_features(disabled_features);
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_checkbox(feature) {
        let working_features = {...var_working_features};
        working_features[feature] = working_features[feature] === 'YES' ? 'NO' : 'YES';
        if (working_features[feature] === 'NO'){
            for(let index in feature_list){
                if(feature_list[index].sub_category === feature || feature_list[index].second_category === feature){
                    working_features[feature_list[index].feature] = 'NO';
                }
            }
        }
        set_working_features(working_features);
    }

    function onClick_save() {
        if (var_processing) return;

        save_record();
    }

    function calculate_indent_level(feature) {
        let indent_level = 0;
        if (feature.sub_category) {
            indent_level += 1 + calculate_indent_level(feature_list.find(item => item.feature === feature.sub_category));
        }
        return indent_level;
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='organizations_details__crd_features'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Features</div>
                    <div className='text--sm-regular'>Configure the product features for the organization.</div>
                </div>
                <div className='card__header__right'>
                    {var_mode === 'VIEW' &&
                    <Button className='secondary' onClick={() => onClick_edit()}>Edit</Button>
                    }
                </div>
            </div>

            <div className='card__content'>
                    {var_mode === 'VIEW' ?
                        <Form>
                            {feature_list.map(item => {
                                let indent_level = calculate_indent_level(item);
                                let style = {};
                                if (indent_level > 0) {
                                    style.marginLeft = `${indent_level * 2}rem`;
                                    style.marginRight = `-${indent_level * 2}rem`;
                                }
                                return (
                                    <div className={'detailsgroup descriptive'} key={'feature-' + item.feature}>
                                        <Form.Field>
                                            <CHECKBOX name={item.feature}
                                                    className={'checkbox_feature' + (indent_level === 0 ? ' parent' : '')}
                                                    style={style}
                                                    label={item.name}
                                                    value={item.code}
                                                    checked={var_features[item.feature] === 'YES'}
                                                    onChange={() => onChange_checkbox(item.feature)}
                                                    disabled={true} />
                                        </Form.Field>
                                        <div className={(item.sub_category ? '' : 'label__parent') + ' disabled'}>{item.info}</div>
                                    </div>
                                );
                            })}
                        </Form>
                    : var_mode === 'EDIT' ?
                        <Form>
                            {feature_list.map(item => {
                                let indent_level = calculate_indent_level(item);
                                let style = {};
                                if (indent_level > 0) {
                                    style.marginLeft = `${indent_level * 2}rem`;
                                    style.marginRight = `-${indent_level * 2}rem`;
                                }
                                return (
                                    <div className={'detailsgroup descriptive'} key={'feature-' + item.feature}>
                                        <Form.Field>
                                            <CHECKBOX name={item.feature}
                                                    className={'checkbox_feature' + (indent_level === 0 ? ' parent' : '')}
                                                    style={style}
                                                    label={item.name}
                                                    value={item.code}
                                                    checked={var_working_features[item.feature] === 'YES'}
                                                    onChange={() => onChange_checkbox(item.feature)}
                                                    disabled={var_disabled_features.includes(item.feature) || (var_working_features[item.sub_category] === 'NO')} />
                                        </Form.Field>
                                        <div className={(item.sub_category ? '' : 'label__parent') + (item.sub_category && var_features[item.sub_category] !== 'YES' ? ' disabled' : '')}>{item.info}</div>
                                    </div>
                                );
                            })}
                            <div className='card__footer'>
                                <Button className='primary' onClick={onClick_save}>Save</Button>
                                <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                            </div>
                        </Form>
                    : null
                    }
            </div>
        </div>
    );
};