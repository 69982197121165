import React, { useState } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../components/cmp_icon';
import { CHECKBOX } from '../../../../components/cmp_form/cmp_form';



export default function CRD_FEATURES({ var_organization, set_organization, status, onClick_nextstep }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_error, set_error ] = useState(false);

    const feature_list = [
        { feature: 'assignments', name: 'Assignments', info: 'Enable the organization to create assignments.' },
        { feature: 'networks', name: 'Affiliate network', sub_category: 'assignments', info: 'Enable the organization to create an affiliate network.' },
        { feature: 'network_invite', name: 'Externally visible', sub_category: 'networks', info: 'Make this organization visible to other entities, so they can invite it to join their affiliate network.' },
        { feature: 'dailycheck', name: 'Daily Check', sub_category: 'assignments', info: 'Create and present a daily questionnaire.' },
        { feature: 'r2w', name: 'Onsite', sub_category: 'assignments', info: 'Allow individuals to declare they are physically on a work site.' },
        { feature: 'qraccess', name: 'QR Access', sub_category: 'assignments', info: 'Enable QR code access for assignments.' },
        { feature: 'requirements', name: 'Requirements', sub_category: 'assignments', info: 'Manage credential compliance with requirements and packages.' },
        { feature: 'credentials', name: 'Credentials', info: 'Allow an Admin to view an individual\'s credentials from an assignment and the worker\'s profile.' },
        { feature: 'manage_credentials', name: 'Manage', sub_category: 'credentials', info: 'Allow an Admin to activate/deactivate an individual\'s credentials.' },
        { feature: 'demoaccount', name: 'Demo ORG', info: 'Create as a demo organization.' },
        { feature: 'documents', name: 'Documents', info: 'Enable the uploading of documents to be acknowledged by individuals.' },
        { feature: 'guest', name: 'Guest', info: 'Allow guests to join the organization.' },
        { feature: 'issuer', name: 'Issuer', info: 'Enable the organization to issue credentials.' },
        { feature: 'credentials_ext', name: 'External Credentials', sub_category: 'issuer', info: 'Make the issuer and its credentials visible to individuals outside of this organization.' },
        //{ feature: 'wallet', name: 'Org Wallet', info: 'View an organization\'s credentials.' },
        { feature: 'reports', name: 'Reports', info: 'Enable reports.' },
        { feature: 'verifiable_credentials', name: 'Verifiable Credentials', info: 'Allow the organization to issue verifiable credentials.' },
        { feature: 'proof_type', name: 'Proof Type Selection', sub_category: 'verifiable_credentials', info: 'Allow the issuer to select the data proof type for the VC.' },
        { feature: 'mfa', name: 'MFA', info: 'Allows users of the organization to opt into MFA.' }
    ];



    //  event listeners ------------------------------------------------------------------------------------------------



    //  functions ------------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_checkbox(feature, organization = null) {
        let set_organization_on_complete = false;
        if (organization === null) {
            set_organization_on_complete = true;
            organization = { ...var_organization };
        }
        organization[feature] = organization[feature] === 'YES' ? 'NO' : 'YES';
        if (organization[feature] === 'NO') {
            for (let item of feature_list) {
                if (item.sub_category === feature && organization[item.feature] === 'YES') {
                    onChange_checkbox(item.feature, organization);
                }
            }
        }
        if (set_organization_on_complete) {
            set_organization(organization);
        }
    }

    function onClick_next() {
        // Make sure at least one feature is selected
        if (feature_list.filter(item => var_organization[item.feature] && var_organization[item.feature] === 'YES').length === 0) {
            set_error(true);
            return
        }
        set_error(false);
        // Make sure all fields are populated
        let organization = {...var_organization};
        feature_list.forEach(item => {
            !organization[item.feature] && (organization[item.feature] = 'NO');
        });
        set_organization(organization);
        onClick_nextstep();
    }

    function calculate_indent_level(feature) {
        let indent_level = 0;
        if (feature.sub_category) {
            indent_level += 1 + calculate_indent_level(feature_list.find(item => item.feature === feature.sub_category));
        }
        return indent_level;
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {status === 'CURRENT' && render_form()}
        </>
    );

    function render_form() {
        return (
            <>
            {var_error &&
                <Message error
                         icon={<Icon name='error' className='icon' />}
                         header={'Please select at least one feature.'}
                />
                }
                <Form>
                    {feature_list.map(item => {
                        let indent_level = calculate_indent_level(item);
                        let style = {};
                        if (indent_level > 0) {
                            style.marginLeft = `${indent_level * 2}rem`;
                            style.marginRight = `-${indent_level * 2}rem`;
                        }
                        return (
                            <div className={'detailsgroup descriptive'} key={'feature-' + item.feature}>
                                <Form.Field>
                                    <CHECKBOX name={item.feature}
                                            className={'checkbox_feature' + (indent_level === 0 ? ' parent' : '')}
                                            style={style}
                                            label={item.name}
                                            value={item.code}
                                            checked={var_organization[item.feature] === 'YES'}
                                            onChange={() => onChange_checkbox(item.feature)}
                                            disabled={(item.sub_category ? var_organization[item.sub_category] !== 'YES' : false)} />
                                </Form.Field>
                                <div className={(item.sub_category ? '' : 'label__parent') + (item.sub_category && var_organization[item.sub_category] !== 'YES' ? ' disabled' : '')}>{item.info}</div>
                            </div>
                        );
                    })}
                </Form>

                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_next}>Continue to step 3</Button>
                </div>
            </>
        );
    }
};
